.glink{
  color: #000000;
  font-size: 1.2rem;
  text-decoration: none;
  margin: 0 10px;
}
.gtranslate_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
  padding: 0 20px;
  max-width: max-content;
  margin: auto;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none;
}

.loading-img{
  max-height: 200px;
  border-radius: 20px;
  max-width: 80%;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
   */

  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255, 0.318));
  transition: all 1s ease-in-out;
}

.loading-div {
 text-align: center;
 animation: zoomIn 0.3s ease-in-out;
}


@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}
